import { apiSlice } from './apiSlice'
import { dynamodbUpdateItemOptimistic } from '@/utils/dynamodb'
import { UpdateItemParams } from 'dynamodb-helpers'
import { AchievementId, MissionId } from '@/types/game'
import { builderHelpers } from './builder'
import { FeatureAccessUpdate, UserFeatureAccess } from '@/types/game/featureAccess'
import { setFeatureAccess } from '../feature-access/featureAccessSlice'
import { RuleOfThreeDay, RuleOfThreeSettings } from '@/types/techniques'
import { NewPartialId } from '@/types/newItem'
import { ALL_LESSONS, LessonId } from '@/features/game/levels/config/lessons/lessons'
import { RequirementId } from '@/features/game/levels/config/types'
import { Wallet, WalletSchema } from '@/types/wallet'

export const gameApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => {
        const { builderItemGET, builderItemPATCH, builderArrayItemPUT, builderArrayItemPATCH } = builderHelpers(builder)

        return {
            getWallet: builderItemGET<Wallet | null, void>({
                url: (date) => `user/wallet`,
                tag: 'wallet',
            }),
            getRuleOfThree: builderItemGET<RuleOfThreeDay | null, string>({
                url: (date) => `rule-of-three/day/${date}`,
                tag: 'rule-of-three',
            }),
            putRuleOfThree: builder.mutation<void, { day: string; updates: UpdateItemParams<RuleOfThreeDay> }>({
                query: ({ day, updates }) => ({ url: `rule-of-three/day/${day}`, method: 'PATCH', body: updates }),
                invalidatesTags: (_, __, arg) => [{ type: 'rule-of-three', id: arg.day }],
                // TODO: onQueryStarted
            }),
            updateRuleOfThree: builderItemPATCH<NewPartialId<RuleOfThreeDay>, { day: string }>({
                url: ({ day }) => `rule-of-three/day/${day}`,
                keyBy: 'day',
                tag: 'rule-of-three',
                getItemEndpointName: 'getRuleOfThree',
            }),
            getRuleOfThreeSettings: builder.query<RuleOfThreeSettings | null, void>({
                query: () => `rule-of-three/settings`,
            }),
            getFeatureAccess: builderItemGET<UserFeatureAccess, 'enabled' | 'unlocked'>({ url: 'game/feature-access', tag: 'feature-access' }),
            updateFeatureAccess: builder.mutation<void, { id: 'enabled' | 'unlocked'; updates: FeatureAccessUpdate }>({
                query: (updateParams) => ({
                    url: `game/feature-access/${updateParams.id}`,
                    method: 'PATCH',
                    body: updateParams.updates,
                }),
                invalidatesTags: (_, __, arg) => [{ type: 'feature-access', id: arg.id }],
                async onQueryStarted({ id, updates }, { dispatch, queryFulfilled }) {
                    const patchedResults = [
                        dispatch(
                            gameApiSlice.util.updateQueryData('getFeatureAccess', id, (data) => {
                                return {
                                    ...data,
                                    features: data.features.concat(updates.add || []).filter((x) => {
                                        return !updates.remove || !updates.remove.includes(x)
                                    }),
                                }
                            })
                        ),
                    ]
                    if (id === 'enabled') dispatch(setFeatureAccess(updates)) // doesn't have an undo
                    queryFulfilled.catch(() => {
                        patchedResults.map((patchedResult) => patchedResult.undo())
                    })
                },
            }),
            getLessonData: builderItemGET<Record<string, any> | null, LessonId>({ url: (lessonId) => `game/lesson/${lessonId}`, tag: 'lesson-data' }),
            putLessonData: builder.mutation<void, { lessonId: LessonId; item: Record<string, any> }>({
                query: ({ lessonId, item }) => ({
                    url: `game/lesson/${lessonId}`,
                    method: 'POST',
                    body: item,
                }),
                async onQueryStarted({ lessonId, item }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getLessonData', lessonId, (data) => {
                            return { ...data, ...item }
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                    })
                },
            }),
            claimLessonChallengeReward: builder.mutation<void, { lessonId: LessonId; challengeId: RequirementId }>({
                query: ({ lessonId, challengeId }) => ({
                    url: `game/lesson/${lessonId}/${challengeId}/claim-reward`,
                    method: 'POST',
                }),
                invalidatesTags: (result, error, item) => [{ type: 'lesson-data', id: item.lessonId }, { type: 'wallet' }],
                async onQueryStarted({ lessonId, challengeId }, { dispatch, queryFulfilled }) {
                    const challenge = ALL_LESSONS[lessonId].challenges.find((c) => c.id === challengeId)
                    const patchedResults = [
                        dispatch(
                            gameApiSlice.util.updateQueryData('getLessonData', lessonId, (data) => {
                                if (!data) return data
                                data[challengeId] = { claimedAt: Date.now() }
                            })
                        ),
                        dispatch(
                            gameApiSlice.util.updateQueryData('getWallet', undefined, (data) => {
                                if (!challenge) return data
                                if (!data) return WalletSchema.parse({ balance: challenge.rewardAmount })
                                data.balance += challenge.rewardAmount
                            })
                        ),
                    ]
                    // gameApiSlice.util.updateQueryData('getWallet', undefined, (data) => {
                    //     return { ...data, ...item }
                    // })
                    patchedResults.map((patchedResult) => {
                        queryFulfilled.catch(() => {
                            patchedResult.undo()
                        })
                    })
                },
            }),
            updateLessonData: builder.mutation<void, { lessonId: LessonId; updates: UpdateItemParams }>({
                query: ({ lessonId, updates }) => ({
                    url: `game/lesson/${lessonId}`,
                    method: 'PATCH',
                    body: updates,
                }),
                async onQueryStarted({ lessonId, updates }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getLessonData', lessonId, (data) => {
                            return dynamodbUpdateItemOptimistic(data || {}, updates)
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                    })
                },
            }),
            getAchievements: builder.query<{ [id in AchievementId]: number }, void>({
                query: () => `game/achievement/all`,
            }),
            getAchievement: builderItemGET<Record<string, any>, AchievementId>({
                url: (lessonId) => `game/achievement/${lessonId}`,
                tag: 'achievement',
            }),
            putAchievement: builder.mutation<void, { achievementId: AchievementId; proof?: Record<string, any> }>({
                query: ({ achievementId, proof }) => ({
                    url: `game/achievement/${achievementId}`,
                    method: 'PUT',
                    body: proof,
                }),
                invalidatesTags: (result, error, item) => [{ type: 'achievement', id: item.achievementId }, { type: 'achievement' }],
                async onQueryStarted({ achievementId }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getAchievements', undefined, (data) => {
                            if (achievementId in data) {
                                data[achievementId]++
                            } else {
                                data[achievementId] = 1
                            }
                        })
                    )
                    const patchedResult2 = dispatch(
                        gameApiSlice.util.updateQueryData('getAchievement', achievementId, (data) => {
                            return { createdAt: Date.now() }
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                        patchedResult2.undo()
                    })
                },
            }),
            getMissions: builder.query<Set<MissionId>, void>({
                query: () => `game/achievement/all`,
                transformResponse: function (baseQueryReturnValue, meta, arg) {
                    if (!Array.isArray(baseQueryReturnValue)) {
                        throw new Error('getMission expected array')
                    }
                    return new Set(baseQueryReturnValue as MissionId[])
                },
            }),
            getMission: builder.query<Record<string, any>, MissionId>({
                query: (lessonId) => `game/achievement/${lessonId}`,
            }),
            putMission: builder.mutation<void, { missionId: MissionId; proof?: Record<string, any> }>({
                query: ({ missionId, proof }) => ({
                    url: `game/achievement/${missionId}`,
                    method: 'POST',
                    body: proof,
                }),
                async onQueryStarted({ missionId }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getMissions', undefined, (data) => {
                            data.add(missionId)
                        })
                    )
                    const patchedResult2 = dispatch(
                        gameApiSlice.util.updateQueryData('getMission', missionId, (data) => {
                            return { createdAt: Date.now() }
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                        patchedResult2.undo()
                    })
                },
            }),
        }
    },
})
