import { FeatureEnum } from '@/features/game/levels/config/types'
import { Plugin } from '@/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fullApiSlice } from '../api'
import { FeatureAccessUpdate } from '@/types/game/featureAccess'

type State = {
    featureAccessMap: { [key in FeatureEnum]?: boolean }
    plugins: Record<Plugin, boolean>
}

// store feature access, 0-unlocked but unused 1-unlocked and used
// don't store locked features

const initialState: State = {
    featureAccessMap: {
        'i.task-difficulty': true,
        'planning-mode': true,
        kanban: true,
        'daily-list': true,
        'capture-list': false,
        'syllabus-parser': true,
        goals: true,
        'multi-select-tasks': true,
        // 'db-template': true,
        // 'i.task-estimated-time': true,
        // 'snack-list': false,
        // chronotype: false,
        // 'power-hours': false,
    },
    plugins: {
        alpha: false,
        student: false,
        beta: false,
        ubc: false,
        mobileApp: false, // if connected to mobile app
    },
}

const alphaFeatures: FeatureEnum[] = ['capture-list']
const betaFeatures: FeatureEnum[] = ['rule-of-three']

const unlockFeatures = (features: FeatureEnum[], state: State) => {
    for (let feat of features) {
        if (!(feat in state.featureAccessMap)) {
            state.featureAccessMap[feat] = false // unlock features
        }
    }
}

const enableFeatures = (features: FeatureEnum[], state: State) => {
    for (let feat of features) {
        state.featureAccessMap[feat] = true // enable features
    }
}

const levelSlice = createSlice({
    name: 'feature-access',
    initialState,
    reducers: {
        setFeatureAccess: (state, action: PayloadAction<FeatureAccessUpdate>) => {
            const { add, remove } = action.payload
            if (add) {
                for (let feat of add) {
                    state.featureAccessMap[feat as FeatureEnum] = true
                }
            }
            if (remove) {
                for (let feat of remove) {
                    state.featureAccessMap[feat as FeatureEnum] = false
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(fullApiSlice.endpoints.getUser.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { level, plugins } = action.payload
            if (plugins) {
                for (const p of plugins) {
                    state.plugins[p] = true
                }
            }

            if (state.plugins['alpha']) {
                state.plugins['beta'] = true
                unlockFeatures(alphaFeatures, state)
            }
            if (state.plugins['beta']) {
                unlockFeatures(betaFeatures, state)
            }
        })

        builder.addMatcher(fullApiSlice.endpoints.getFeatureAccess.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { id, features } = action.payload
            if (id.toLowerCase().includes('enabled')) {
                enableFeatures(features, state)
            } else {
                unlockFeatures(features, state)
            }
        })

        builder.addMatcher(fullApiSlice.endpoints.getMobile.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { isPaired } = action.payload
            state.plugins['mobileApp'] = isPaired
        })
    },
})

export const { setFeatureAccess } = levelSlice.actions
export default levelSlice.reducer
