import { LessonConfig } from "./types";

const DAILY_LIST_CONFIG: LessonConfig = {
    title: "Daily list",
    description: "Fight decision paralysis with a focused list",
    visual: {
        type: 'image',
        imgSrc: '/images/kanban.png',
        imageTitle: 'Organize tasks into columns',
        width: 2662,
        height: 1684,
    },
    bullets: [
        'Backlog: Ideas or tasks not yet prioritized',
        'TODO: What you are working on next',
        'In progress: What you are currently working on',
        'Complete: Finished tasks',
    ],
    featuresUnlocked: ['daily-list'],
    challenges: [
        {
            text: 'Have at least 3 items in your daily list',
            id: 'daily-list-add-three',
            rewardAmount: 5,
            achievementsRequired: 'kanban-backlog-to-todo',
        },
        {
            text: 'Complete at least 3 items on your daily list',
            times: 3,
            id: 'daily-list-complete-three',
            rewardAmount: 30,
            achievementsRequired: { key: 'kanban-full-cycle', op: "GEQ", value: 3 },
        },
    ]
}

export default DAILY_LIST_CONFIG
