// Need to use the React-specific entry point to import createApi
import { CalendarItem } from '@/features/calendars/types'
import { dynamodbUpdateItemOptimistic } from '@/utils/dynamodb'
import { RootState } from '@/redux/store'
import {
    Category,
    CategoryRes,
    CategorySchema,
    DatedItem,
    DatedRes,
    Item,
    NewItem,
    Section,
    SectionSchema,
    Task,
    TodoGroup,
    TodoGroupSchema,
    getEmptyCategoryRes,
    getInfoFromCategoryPath,
    isDatedItem,
} from '@/types'
import { New } from '@/types/newItem'
import { WorkBlockInfo } from '@/types/workBlock'
import { parseCategoryUnitUpdates } from '@/utils/categories'
import { getCategoryOptimisticData, shallowRemoveCategoryUnit } from '@/utils/category'
import { createNewItemPlaceholder, getTypeFromId } from '@/utils/item'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { UpdateItemParams } from 'dynamodb-helpers'
import { cloneDeep } from 'lodash'
import { SmartGoal, StretchGoal, StretchGoalsOrder } from '@/types/goal'
import { Idea } from '@/types/idea'
import { tagTypes, builderHelpers } from './builder'
import { MascotSettings } from '@/types/mascot'

/**
 * TODO: useCategories
 */
// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
    reducerPath: 'api',
    tagTypes,
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        prepareHeaders: (headers, { getState }) => {
            const { viewMode } = getState() as RootState
            if (viewMode.shareLinkUrlInfo) {
                headers.set('Sharing-Id', viewMode.shareLinkUrlInfo.sharingId)
                headers.set('Sharing-By', viewMode.shareLinkUrlInfo.sharingBy)
            }
            return headers
        },
    }),
    endpoints: (builder) => {
        const { builderArrayGET, builderArrayItemPUT, builderArrayItemPATCH, builderItemGET, builderItemPATCH } = builderHelpers(builder)

        return {
            getMascotSettings: builderItemGET<MascotSettings>({ url: 'mascot', tag: 'mascot-settings' }),
            updateMascotSettings: builderItemPATCH<MascotSettings>({
                url: 'mascot',
                tag: 'mascot-settings',
                getItemEndpointName: 'getMascotSettings',
                keyBy: null,
            }),
            // should Ideas just be tasks without a .completed? maybe ideas can have an expiry date
            getIdeas: builderArrayGET<Idea>({ url: 'main/idea', tag: 'idea' }),
            putIdea: builderArrayItemPUT<New<Idea>>({ url: 'main/idea', tag: 'idea', getArrayEndpointName: 'getIdeas' }),
            putStretchGoal: builderArrayItemPUT<StretchGoal>({ url: 'goal/stretch', tag: 'goal', getArrayEndpointName: 'getStretchGoals' }),
            putSmartGoal: builderArrayItemPUT<SmartGoal>({
                url: (item) => `goal/stretch/${item.stretchGoalId}/smart/${item.id}`,
                tag: 'goal',
                getArrayEndpointName: 'getSmartGoalsOfStretchGoal',
                getArrayEndpointArgs: (item) => item.stretchGoalId,
            }),
            updateSmartGoal: builderArrayItemPATCH<SmartGoal, { stretchGoalId: string }>({
                url: (item) => `goal/stretch/${item.stretchGoalId}/smart/${item.id}`,
                tag: 'goal',
                getArrayEndpointName: 'getSmartGoalsOfStretchGoal',
            }),
            putStretchGoalsOrder: builder.mutation<void, string[]>({
                query: (item) => ({
                    url: `goal/stretch/order`,
                    method: 'PUT',
                    body: item,
                }),
                invalidatesTags: ['goal-order'],
                async onQueryStarted(newOrder, { dispatch, queryFulfilled }) {
                    const patchedResults = [
                        dispatch(apiSlice.util.updateQueryData('getStretchGoalsOrder', undefined, (data) => ({ ...data, order: newOrder }))),
                    ]
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo())
                    })
                },
            }),
            getStretchGoalsOrder: builder.query<StretchGoalsOrder, void>({
                query: () => `goal/stretch/order`,
                providesTags: ['goal-order'],
            }),
            getStretchGoals: builderArrayGET<StretchGoal>({ url: `goal/stretch`, tag: 'goal' }),
            getSmartGoalsOfStretchGoal: builderArrayGET<SmartGoal, string>({ url: (stretchId) => `goal/stretch/${stretchId}/smart`, tag: 'goal' }),
            getWorkBlockInfo: builder.query<WorkBlockInfo | undefined, string>({
                query: (id: string) => `work-block/${id}`,
                providesTags(result, error, arg, meta) {
                    return [{ type: 'workBlock', id: arg }]
                },
            }),
            addToWorkBlock: builder.mutation<void, { id: string; taskId: string; taskInfo?: Partial<Task> }>({
                query: ({ id, taskId }) => {
                    return {
                        url: `work-block/${id}`,
                        method: 'PATCH',
                        body: { id: id, taskIds: [taskId] },
                    }
                },
                invalidatesTags: (result, error, { id }) => [{ type: 'workBlock', id }],
                async onQueryStarted({ id, taskId, taskInfo }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getWorkBlockInfo', id, (data) => {
                            const task = { ...taskInfo, type: 'task' } as Task // TODO: is this dangerous?
                            if (data?.taskIds && data.taskIds.includes(taskId)) return data
                            // const task = { ...taskInfo, type: 'task', name: name.startsWith("Work on ") ? name.slice(("Work on ").length) : name }
                            if (!data)
                                return {
                                    id: id,
                                    taskIds: [taskId],
                                    tasks: [task],
                                }
                            return {
                                ...data,
                                tasks: [...(data.tasks || []), task],
                                taskIds: [...(data.taskIds || []), taskId],
                            }
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            removeFromWorkBlock: builder.mutation<void, { id: string; taskIds: string[] }>({
                query: ({ id, taskIds }) => {
                    taskIds = taskIds.filter(Boolean)

                    return {
                        url: `work-block/${id}`,
                        method: 'DELETE',
                        body: { taskIds },
                    }
                },
                invalidatesTags: (result, error, { id }) => [{ type: 'workBlock', id }],
                async onQueryStarted({ id, taskIds }, { dispatch, queryFulfilled }) {
                    taskIds = taskIds.filter(Boolean)
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getWorkBlockInfo', id, (data) => {
                            if (!data?.taskIds && !data?.tasks) return data as any
                            return {
                                ...data,
                                tasks: data.tasks?.filter((x) => !taskIds.some((taskId) => taskId === x.id)),
                                taskIds: data.taskIds?.filter((x) => !taskIds.some((taskId) => taskId === x)),
                            }
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            getCategoryRes: builder.query<CategoryRes, void>({
                query: () => `main/category`,
                providesTags: (result, error, arg, meta) => {
                    const defaultTags = [{ type: 'event' }, { type: 'task' }, { type: 'category' }] as const
                    if (!result) return defaultTags
                    const itemTags = Object.values(result.items)
                        .flat()
                        .map(({ id, type }) => ({ type, id }))
                    // TODO: tags for category ids
                    return [...itemTags, ...defaultTags]
                },
                transformResponse: async (response: CategoryRes) => {
                    if (typeof window !== 'undefined') {
                        import('localforage').then(async ({ default: localforage }) => {
                            localforage.setItem('categories', response.categoryUnits)
                        })
                    }
                    return response
                },
            }),
            getDatedItems: builder.query<DatedRes, { start: number; end: number; convertTemplateRecurs?: boolean }>({
                query: ({ start, end, convertTemplateRecurs = false }) =>
                    `date?start=${start}&end=${end}&convertTemplateRecurs=${convertTemplateRecurs}`,
                providesTags: (result, error, { start, end }) => [
                    { type: 'event' },
                    { type: 'task' },
                    ...(result?.map(({ type, id }) => ({ type, id })) || [
                            { type: 'event', id: `${start}-${end}` },
                            { type: 'task', id: `${start}-${end}` },
                            // also possible TemplateRecur
                        ] ||
                        []),
                ],
                async onQueryStarted({ start, end }, { dispatch, queryFulfilled, getState, updateCachedData, getCacheEntry }) {
                    const categoryRes = apiSlice.endpoints.getCategoryRes.select()(getState()).data
                    if (categoryRes) {
                        dispatch(
                            // no need to undo this
                            apiSlice.util.updateQueryData('getDatedItems', { start, end }, (draft) => {
                                if (draft && draft.length > 0) return draft
                                const datedTasks = categoryRes.items.task.filter((x) => x.dateStart)
                                return [...categoryRes.items.event, ...datedTasks, ...categoryRes.items.templateRecur] as DatedItem[]
                            })
                        )
                    }
                },
            }),
            deletePlannerItem: builder.mutation<void, string>({
                query: (id) => ({
                    url: 'main/item',
                    method: 'DELETE',
                    body: { id },
                }),
                invalidatesTags: (result, error, arg, meta) => [{ type: getTypeFromId(arg), id: arg }],
                async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
                    const patchedResults: any[] = []
                    const type = getTypeFromId(id)
                    for (const { endpointName, originalArgs } of apiSlice.util.selectInvalidatedBy(getState(), [{ type, id }])) {
                        // we only want to update `getPosts` here
                        if (endpointName === 'getCategoryRes') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData('getCategoryRes', undefined, (draft) => {
                                    const items = draft.items[type]
                                    // @ts-expect-error
                                    draft.items[type] = items.filter((x) => x.id !== id)
                                    return draft
                                })
                            )
                            patchedResults.push(res)
                            continue
                        } else if (endpointName === 'getDatedItems') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData('getDatedItems', originalArgs, (draft) => {
                                    return draft.filter((x) => x.id !== id)
                                })
                            )
                            patchedResults.push(res)
                        }
                    }
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            updatePlannerItem: builder.mutation<void, { id: string; updates: UpdateItemParams<Item> }>({
                query: ({ id, updates }) => {
                    const remove =
                        updates.set && Object.keys(updates.set).filter((key) => updates.set![key as keyof typeof updates.set] === undefined)
                    if (remove) {
                        if (!updates.remove) updates.remove = []
                        updates.remove = updates.remove.concat(remove)
                    }

                    return {
                        url: 'main/item',
                        method: 'PATCH',
                        body: { ...updates, id },
                    }
                },
                invalidatesTags: (result, error, arg, meta) => [{ type: getTypeFromId(arg.id), id: arg.id }],
                async onQueryStarted({ id, updates }, { dispatch, queryFulfilled, getState }) {
                    const patchedResults: any[] = []
                    for (const { endpointName, originalArgs } of apiSlice.util.selectInvalidatedBy(getState(), [
                        { type: getTypeFromId(id), id: id },
                    ])) {
                        // we only want to update `getPosts` here
                        if (endpointName === 'getCategoryRes') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData('getCategoryRes', undefined, (draft) => {
                                    const type = getTypeFromId(id)
                                    // @ts-expect-error
                                    draft.items[type] = draft.items[type].map((x) => {
                                        // TODO: what about not updates.set?
                                        if (x.id !== id) return x
                                        return dynamodbUpdateItemOptimistic(x, updates)
                                    })
                                })
                            )
                            patchedResults.push(res)
                            continue
                        } else if (endpointName === 'getDatedItems') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData('getDatedItems', originalArgs, (draft) => {
                                    return draft.map((x) =>
                                        x.id === id ? dynamodbUpdateItemOptimistic(cloneDeep(x), cloneDeep(updates)) : x
                                    ) as DatedRes
                                })
                            )
                            patchedResults.push(res)
                        }
                    }
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            putPlannerItemNLP: builder.mutation<{ success: boolean; item: Item }, { text: string }>({
                query: (item) => ({
                    url: 'main/item/nlp',
                    method: 'POST',
                    body: item,
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'task' },
                    { type: 'event' },
                    { type: 'templateRecur' },
                    { type: 'achievement' },
                ],
            }),
            putPlannerItem: builder.mutation<void, { item: NewItem | Item | CalendarItem; isNew?: boolean }>({
                query: ({ item }) => ({
                    url: 'main/item',
                    method: 'PUT',
                    body: item,
                }),
                invalidatesTags: (result, error, { item, isNew }, meta) => [
                    // TODO: not sure how to handle this
                    { type: item.type, id: item.id },
                    { type: item.type },
                    {
                        type: 'achievement',
                        id: item.type === 'task' ? 'create-task-with-form' : item.type === 'event' ? 'create-event-with-form' : undefined,
                    },
                    // ...(isNew ? [{ type: item.type }] : []),
                ],
                async onQueryStarted({ item: initialItem }, { dispatch, queryFulfilled, getState }) {
                    if (!initialItem.id && !initialItem.type) {
                        if ('dateEnd' in initialItem && initialItem.dateEnd) {
                            initialItem.type = 'dateEnd' in initialItem && (initialItem as { dateEnd: number }).dateEnd ? 'event' : 'task'
                        }
                    } else if (initialItem.id && !initialItem.type) {
                        initialItem.type = getTypeFromId(initialItem.id)
                    }
                    const item = initialItem as Item | NewItem

                    const patchedResults: any[] = []
                    for (const { endpointName, originalArgs } of apiSlice.util.selectInvalidatedBy(getState(), [
                        { type: item.type, id: item.id },
                        { type: item.type },
                    ])) {
                        // we only want to update `getPosts` here
                        if (endpointName === 'getCategoryRes') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData('getCategoryRes', undefined, (draft) => {
                                    if (item.id) {
                                        const type = getTypeFromId(item.id)
                                        const index = draft.items[type].findIndex((x) => x.id === item.id)
                                        if (index === -1) {
                                            draft.items[item.type].push(
                                                // @ts-expect-error
                                                createNewItemPlaceholder(item as NewItem)
                                            )
                                        } else {
                                            draft.items[type][index] = item as Item
                                        }
                                    } else {
                                        draft.items[item.type].push(
                                            // @ts-expect-error
                                            createNewItemPlaceholder(item as NewItem)
                                        )
                                    }
                                })
                            )
                            patchedResults.push(res)
                            continue
                        } else if (endpointName === 'getDatedItems') {
                            if (isDatedItem(item)) {
                                const res = dispatch(
                                    apiSlice.util.updateQueryData('getDatedItems', originalArgs, (draft) => {
                                        const index = item.id ? draft.findIndex((x) => x.id === item.id) : -1
                                        if (index === -1) {
                                            draft.push(createNewItemPlaceholder(item) as DatedItem)
                                        } else {
                                            // TODO: make sure this doesn't break anything (worried about multi-line events + recurring)
                                            draft[index] = Object.assign(draft[index], item)
                                        }
                                    })
                                )
                                patchedResults.push(res)
                            }
                        }
                    }
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            putCategoryUnit: builder.mutation<
                void,
                {
                    dbProperties: Partial<Category> | Partial<Section>
                    path?: string
                }
            >({
                query: ({ path, dbProperties }) => ({
                    url: 'main/categoryUnit',
                    method: 'POST',
                    body: {
                        path,
                        ...dbProperties,
                    },
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                    { type: 'achievement', id: 'category-unit-create-section' },
                    { type: 'achievement', id: 'category-unit-create-category' },
                ],
                async onQueryStarted({ path, dbProperties }, { dispatch, queryFulfilled }) {
                    const { isCategory, isSection, isGroup } = getInfoFromCategoryPath(path)
                    const patchedResults = [
                        dispatch(
                            apiSlice.util.updateQueryData('getCategoryRes', undefined, (x) => {
                                if (!x) return getEmptyCategoryRes()
                                if ('id' in dbProperties) {
                                    // if id already exists, replace old item, normally use patch though
                                    let categoryUnitsSamePath = isCategory
                                        ? x.categoryUnits?.categories[path!]
                                        : isSection
                                          ? x.categoryUnits?.sections
                                          : x.categoryUnits?.groups[path!]
                                    const index = categoryUnitsSamePath?.findIndex((c) => c.id === dbProperties.id)
                                    if (typeof index === 'number' && index >= 0) {
                                        categoryUnitsSamePath[index] = dbProperties as Section | Category | TodoGroup
                                        return
                                    }
                                }

                                const CATEGORY_UNIT_DEFAULTS = {
                                    ...(!isGroup && { active: 1 }),
                                    createdAt: Date.now(),
                                    updatedAt: Date.now(),
                                }

                                if (isCategory) {
                                    x.categoryUnits?.categories[path!].push(
                                        CategorySchema.parse({
                                            ...dbProperties,
                                            id: (path ? path + '/' : '') + 'new',
                                            ...CATEGORY_UNIT_DEFAULTS,
                                        })
                                    )
                                } else if (isSection) {
                                    x.categoryUnits?.sections.push(
                                        SectionSchema.parse({
                                            ...dbProperties,
                                            id: (path ? path + '/' : '') + 'new',
                                            ...CATEGORY_UNIT_DEFAULTS,
                                        })
                                    )
                                } else if (isGroup) {
                                    x.categoryUnits?.groups[path!].push(
                                        TodoGroupSchema.parse({
                                            ...dbProperties,
                                            id: (path ? path + '/' : '') + 'new',
                                            ...CATEGORY_UNIT_DEFAULTS,
                                        })
                                    )
                                }
                            })
                        ),
                    ]
                    patchedResults.forEach((patchedResult) => queryFulfilled.catch(patchedResult.undo))
                },
            }),
            editCategoryUnit: builder.mutation<void, { path: string; properties: Record<string, any> }>({
                query: ({ path, properties }) => {
                    const updates = parseCategoryUnitUpdates(path, properties)
                    return {
                        url: 'main/categoryUnit',
                        method: 'PATCH',
                        body: {
                            id: path,
                            updates: { set: updates },
                        },
                    }
                },
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                ],
                async onQueryStarted({ path, properties }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getCategoryRes', undefined, (x: CategoryRes) => {
                            getCategoryOptimisticData(path, cloneDeep(properties), x)
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            removeCategoryUnit: builder.mutation<void, { path: string }>({
                query: ({ path }) => ({
                    url: 'main/categoryUnit',
                    method: 'DELETE',
                    body: {
                        id: path,
                    },
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                ],
                async onQueryStarted({ path }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getCategoryRes', undefined, (x: CategoryRes) => {
                            shallowRemoveCategoryUnit(path, x)
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            inactivateCategoryUnit: builder.mutation<void, { path: string; activity?: number }>({
                query: ({ path, activity = 0 }) => ({
                    url: 'main/categoryUnit',
                    method: 'OPTIONS',
                    body: {
                        id: path,
                        activity,
                    },
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                ],
                async onQueryStarted({ path, activity = 0 }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getCategoryRes', undefined, (x: CategoryRes) => {
                            x = getCategoryOptimisticData(path, { active: activity }, x)
                            if (!activity) x = shallowRemoveCategoryUnit(path, x)
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
        }
    },
})
