import {
    EventSchema,
    superRefineEventDates,
    TaskSchema,
    TemplateRecurSchema,
    refineTemplateDB,
    superRefineDates,
    TemplateDBEventSchema,
    TemplateDBTaskSchema,
} from '../item'
import z from 'src/lib/zod'
import { CategorySchema, CategoryUnitSchema, SectionSchema, TodoGroupSchema } from '../categories'
import { LOTS_OF_MS } from '@constants/date'

const VALUES_TO_OMIT = { type: true, categoryName: true } as const
export const DBTaskSchema = TaskSchema.omit(VALUES_TO_OMIT).extend({ userId: z.string() })
export const DBEventSchema = EventSchema.omit(VALUES_TO_OMIT).partial({ name: true }).extend({ userId: z.string() })

export const DBTemplateDBTaskSchema = TemplateDBTaskSchema.omit({ ...VALUES_TO_OMIT, childType: true }).extend({ userId: z.string() })
export const DBTemplateDBEventSchema = TemplateDBEventSchema.omit({ ...VALUES_TO_OMIT, childType: true }).extend({ userId: z.string() })
export const DBTemplateDBSchema = DBTemplateDBTaskSchema.or(DBTemplateDBEventSchema)

export const DBTemplateRecurSchema = TemplateRecurSchema.omit({ ...VALUES_TO_OMIT, childType: true }).extend({
    userId: z.string(),
    dateEnd: z.number().positive().default(LOTS_OF_MS),
})

/** Strict */
export const StrictDBTaskSchema = DBTaskSchema
export const StrictDBEventSchema = DBEventSchema.superRefine(superRefineEventDates)

export const StrictDBTemplateDBTaskSchema = DBTemplateDBTaskSchema.refine(refineTemplateDB)
export const StrictDBTemplateDBEventSchema = DBTemplateDBEventSchema.refine(refineTemplateDB)
export const StrictDBTemplateDBSchema = StrictDBTemplateDBTaskSchema.or(StrictDBTemplateDBEventSchema)

export const StrictDBTemplateRecurSchema = DBTemplateRecurSchema.superRefine(superRefineDates)

/** Types */
export type DBTask = z.infer<typeof DBTaskSchema>
export type DBEvent = z.infer<typeof DBEventSchema>
export type DBTemplateDB = z.infer<typeof DBTemplateDBSchema>
export type DBTemplateRecur = z.infer<typeof DBTemplateRecurSchema>

export type DBItem = DBTask | DBEvent | DBTemplateDB | DBTemplateRecur

export const dbItemSchemas = {
    event: DBEventSchema,
    task: DBTaskSchema,
    templateRecur: DBTemplateRecurSchema,
    templateDB: DBTemplateDBSchema,
}

export const dbItemPartialSchemas = {
    event: DBEventSchema.partial(),
    task: DBTaskSchema.partial(),
    templateRecur: DBTemplateRecurSchema.partial(),
    templateDB: DBTemplateDBTaskSchema.partial().merge(DBTemplateDBEventSchema.partial()),
}

export const DBSectionSchema = SectionSchema.extend({
    userId: z.string(),
    active: z.string().endsWith('.SECTION'),
    id: z.string().startsWith('SECTION.'),
}).strict()
export const DBCategorySchema = CategorySchema.extend({ userId: z.string(), active: z.string(), id: z.string().startsWith('CATEGORY.') }).strict()
export const DBGroupSchema = TodoGroupSchema.extend({ userId: z.string(), id: z.string().startsWith('GROUP.') }).strict()
export const DBCategoryUnitSchema = CategoryUnitSchema.extend({ userId: z.string(), active: z.string() }).strict()
export type DBSection = z.infer<typeof DBSectionSchema>
export type DBCategory = z.infer<typeof DBCategorySchema>
export type DBCategoryUnit = z.infer<typeof DBCategoryUnitSchema>

function getPathFromCategoryUnitId(id: string) {
    if (id.startsWith('CATEGORY.') || id.startsWith('SECTION.')) {
        return id.substring(id.indexOf('.') + 1)
    }
    return id
}

export const DBSectionSchemaCoerce = SectionSchema.extend({ userId: z.string(), active: z.string().or(z.number()) }).transform((val, ctx) => {
    const { active, id } = val
    if (typeof active === 'number') {
        val.active = `${active}.SECTION`
    }
    if (!val.id.startsWith('SECTION.')) {
        val.id = 'SECTION.' + id
    }
    return { ...val } as DBSection
})

export const clientCategoryIdToDBId = (id: string) => {
    return id.split('/').length === 1 ? 'SECTION.' + id : 'CATEGORY.' + id
}

export const DBCategorySchemaCoerce = CategorySchema.extend({ userId: z.string(), active: z.string().or(z.number()) }).transform((val, ctx) => {
    const { active, id } = val
    const path = getPathFromCategoryUnitId(id)
    if (typeof active === 'number') {
        val.active = `${active}.${path.split('.')[0]}`
    }
    if (!val.id.startsWith('CATEGORY.')) {
        val.id = 'CATEGORY.' + path
    }
    return { ...val } as DBCategory
})

export const isDBTaskId = (id: string) => id.startsWith('i.task.')
export const isDBEventId = (id: string) => id.startsWith('i.event.')
export const isDBTemplateDBId = (id: string) => id.startsWith('i.templateDB.')
export const isDBTemplateRecurId = (id: string) => id.startsWith('i.templateRecur.')
