import { categoryColor } from '@/categories'
import { MAX_CATEGORIES } from '@/constants/user'
import { ThemeCustomization } from '@/types/theme'
import { CategoryRes, Category, Section, CategorySchema, SectionSchema, CategoryUnits, TodoGroup, ItemType } from 'src/types'
import { token } from 'styled-system/tokens'

export const categoryColorDynamic = (typeAndNum: string | number | undefined) => {
    const isCustom = typeof typeAndNum === 'number' && typeAndNum > MAX_CATEGORIES
    const { color, backgroundColor } = categoryColor(isCustom ? 1 : typeAndNum)
    if (!color) return {}
    // token
    return {
        // @ts-expect-error
        color: token(`colors.${color}`),
        // @ts-expect-error
        backgroundColor: isCustom ? `var(--category-custom-theme-color-${typeAndNum})` : token(`colors.${backgroundColor}`),
    }
}

export const categoryCustomColorIndexToCSSVar = (i: number) => '--category-custom-theme-color-' + (i + MAX_CATEGORIES + 1)

export const setCustomCategoryColorsGlobally = (initialThemeCustomizations: ThemeCustomization | undefined) => {
    if (typeof document === 'undefined') return
    const customCategoryColors = initialThemeCustomizations?.customCategoryColors
    if (!customCategoryColors?.length) return
    customCategoryColors.forEach((color, i) => {
        if (!color) return
        document.documentElement.style.setProperty(categoryCustomColorIndexToCSSVar(i), color)
    })
}

export const getSections = (categories: CategoryRes | undefined) => categories && Object.values(categories)

export const pathToCategories = (categories: CategoryRes | undefined, path: string | string[], attribute?: string) => {
    if (!categories) return
    path = convertPathToArray(path)

    try {
        let categoryUnitInfo: any = categories
        return path.map((id) => {
            const val = categoryUnitInfo[id]
            categoryUnitInfo = categoryUnitInfo.next
            return attribute ? val[attribute] : val
        }) as [Section, Category] | [Section]
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const pathToNamesArray = (categories: CategoryRes | undefined, path: string | string[]) => {
    if (!categories) return
    return pathToCategories(categories, path)?.map((x) => x.name)
}

export const isValidPath = (categories: CategoryRes | undefined, path: string | string[]) => {
    if (!categories) return
    path = convertPathToArray(path)

    try {
        path.reduce((previousValue, currentValue) => previousValue.next[currentValue], { next: categories } as any)
        return true
    } catch (e) {
        return false
    }
}

export const getCategoryUnit = (categories: CategoryRes | undefined, path: string | string[], attribute?: string) => {
    if (!categories) return
    return pathToCategories(categories, path, attribute)?.at(-1)
}

export const parseCategoryUnitUpdates = (path: string, properties: { [x: string]: any }) => {
    const [sectionId, categoryId] = path.split('/')
    const schema = (categoryId ? CategorySchema : SectionSchema).partial()
    const updates = schema.parse(properties)
    return updates
}

export const getInfoFromCategoryPath = (path?: string) => {
    const isSection = !path
    const isCategory = path && path.split('/').length === 1
    const isGroup = path && path.split('/').length === 2
    return { isSection, isCategory, isGroup }
}
function convertPathToArray(path: string | string[]) {
    if (typeof path === 'string') {
        return path.split('/')
    }
    return path
}

export const getCategoryNumGlobal = (path: string | string[], categoryUnits: CategoryUnits | undefined): number | null => {
    if (!categoryUnits) return null
    if (path === '/') return null
    if (typeof path === 'string') path = path.split('/')
    if (path.length < 1) return null
    return pathToCategoriesGlobal(path, categoryUnits)?.[1]?.colorNum ?? null
}

export const getCategoryName = (path: string, categoryUnits: CategoryUnits | undefined) => {
    const names = pathToNamesArrayGlobal(path, categoryUnits)
    return names?.at(1)
}

export const pathToNamesArrayGlobal = (path: string | string[], categoryUnits: CategoryUnits | undefined) => {
    return pathToCategoriesGlobal(path, categoryUnits)?.map((x) => x.name)
}
export function pathToCategoriesGlobal(
    path: string | string[],
    categoryUnits: CategoryUnits | undefined
): [Section] | [Section, Category] | [Section, Category, TodoGroup] | undefined {
    if (!categoryUnits) return undefined
    if (path === '/') return undefined
    path = convertPathToArray(path)

    const categories = Array.from(Array(path.length))
    categories[0] = categoryUnits.sections.find((section) => section.id === path[0])
    if (!categories[0]) return undefined
    if (categories.length > 1) {
        categories[1] = categoryUnits.categories[categories[0].id].find((category) => {
            return (path as string[]).join('/').startsWith(category.id)
        })
        if (!categories[1]) return undefined
    }
    if (categories.length > 2) {
        categories[2] = categoryUnits.groups[categories[1].id].find((group) => {
            return (path as string[]).join('/').startsWith(group.id)
        })
        if (!categories[2]) return undefined
    }
    return categories as [Section] | [Section, Category] | [Section, Category, TodoGroup]
}
