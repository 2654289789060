import { LessonConfig } from "./types";

const WELCOME_SCAVENGER_HUNT_CONFIG: LessonConfig = {
    title: "Welcome to Planda",
    // description: "",
    visual: {
        // maybe put panda-wave lottie here?
        type: 'image',
        imgSrc: '/images/kanban.png',
        width: 2662,
        height: 1684,
    },
    challenges: [
        {
            text: 'Navigate to the Tasks page',
            id: 'kanban-navigate',
            rewardAmount: 1,
            achievementsRequired: 'kanban-backlog-to-todo',
        },
        {
            text: 'Navigate to the monthly calendar',
            id: 'kanban-lifecycle',
            rewardAmount: 1,
            achievementsRequired: { key: 'kanban-full-cycle', op: "GEQ", value: 3 },
        },
        {
            text: 'Navigate to the monthly calendar and move the panda into the house',
            id: 'kanban-lifecycle',
            rewardAmount: 1,
            achievementsRequired: { key: 'kanban-full-cycle', op: "GEQ", value: 3 },
        },
        {
            text: "Open your week time calendar",
            id: 'ladjfalsfla',
            rewardAmount: 1,
            achievementsRequired: 'add-task-to-daily-list'
        }
    ]
}

export default WELCOME_SCAVENGER_HUNT_CONFIG
