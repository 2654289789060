import CATEGORIES_CONFIG from './categories'
import DAILY_LIST_CONFIG from './dailyList'
import KANBAN_CONFIG from './kanban'
import TASKS_EVENTS_CONFIG from './tasksEvents'
import WELCOME_SCAVENGER_HUNT_CONFIG from './welcomeScavengerHunt'

export const ALL_LESSONS = {
    kanban: KANBAN_CONFIG,
    tasksVersesEvents: TASKS_EVENTS_CONFIG,
    categoriesIntro: CATEGORIES_CONFIG,
    'daily-list': DAILY_LIST_CONFIG,
    welcomeScavengerHunt: WELCOME_SCAVENGER_HUNT_CONFIG,
} as const

Object.freeze(ALL_LESSONS)

export type LessonId = keyof typeof ALL_LESSONS

export const isLessonId = (id: string): id is LessonId => {
    return id in ALL_LESSONS
}
