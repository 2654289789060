import { z } from 'zod'

export const WalletSchema = z.object({
    id: z.string().default('WALLET'),
    balance: z.number(),
    createdAt: z.number().default(Date.now()),
    updatedAt: z.number().default(Date.now()),
})

export type Wallet = z.infer<typeof WalletSchema>
