import { LessonConfig } from "./types";

const KANBAN_CONFIG: LessonConfig = {
    title: "Kanban",
    description: "Simple visualization method for managing tasks",
    visual: {
        type: 'image',
        imgSrc: '/images/kanban.png',
        imageTitle: 'Organize tasks into columns',
        width: 2662,
        height: 1684,
    },
    bullets: ['Prioritize asks effectively', 'Visualize your workflow', 'Limit work in progress'],
    // bullets: [
    //     'Backlog: Ideas or tasks not yet prioritized',
    //     'TODO: What you are working on next',
    //     'In progress: What you are currently working on',
    //     'Complete: Finished tasks',
    // ],
    featuresUnlocked: ['kanban'],
    challenges: [
        {
            text: 'Naviate to the Kanban board and move one task from backlog to TODO',
            id: 'kanban-navigate',
            rewardAmount: 5,
            achievementsRequired: 'kanban-backlog-to-todo',
        },
        {
            text: 'Move a task through every column in the Kanban board',
            note: 'Each task must rest in each column for at least 5 minutes',
            times: 3,
            id: 'kanban-lifecycle',
            rewardAmount: 30,
            achievementsRequired: { key: 'kanban-full-cycle', op: "GEQ", value: 3 },
        },
    ]
}

export default KANBAN_CONFIG
